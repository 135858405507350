import { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { Grid, CircularProgress, } from '@mui/material';
import DashboardLayout from './DashboardLayout.js';
import ProtectedRoute from './ProtectedRoute.js';
const Contacts = lazy(() => import('./contacts/Contacts'));
const OrgDashboard = lazy(() => import('./dashboard/OrgDashboard'));
const EmailTemplateEditor = lazy(() => import('./templates/Editor'));
const ContactDetails = lazy(() => import('./contacts/ContactDetails'));
const Sections = lazy(() => import('./Sections.js'));
const Settings = lazy(() => import('./Settings.js'));
const TeamMembersList = lazy(() => import('./teams/index.js'));
const Organisations = lazy(() => import('./organisations/index.js'));
const PendingOrgJoinRequests = lazy(() => import('./organisations/PendingOrgJoinRequests.js'));
const Segments = lazy(() => import('./segments/index'));
const Survey = lazy(() => import('./survey/SurveyListing.js'))
const NewSurvey = lazy(() => import('./survey/Create.js'))
const AddOrEditSurvey = lazy(() => import('./survey/index.js'))
const Tasks = lazy(() => import('./tasks/TaskListing.js'));
const Task = lazy(() => import('./tasks/index.js'));
const MyTasks = lazy(() => import('./mytasks/MyTasksListing.js'));
const MyTasksDetailDashboard = lazy(() => import('./mytasks/index.js'));
const MyPeople = lazy(() => import('./mypeople/mypeople.js'));
const TemplatesList = lazy(() => import('./templates/TemplatesList.js'));
const ContactForm = lazy(() => import('./contacts/AddContact.js'));
const ExportContacts = lazy(() => import('./contacts/ExportContacts.js'));
const ViewSurveyResponses = lazy(() => import('./survey/ViewSurveyResponses.js'));
const WalkListDetail = lazy(() => import('./mytasks/WalkListDetail.js'));
const SavedListDetailPage = lazy(() => import('./segments/SavedListDetailPage'));
const SavedListWrap = lazy(() => import('./segments/SavedListWrap'));
const Notifications = lazy(() => import('./notifications/index.js'));
const SheetImportHistory = lazy(() => import('./contacts/SheetImportHistory'));
const ExportedContactsHistory = lazy(() => import('./contacts/ExportedContactsHistory'));
const EmailCommunications = lazy(() => import('./templates/index.js'));
const MyConnections = lazy(() => import('./contacts/MyConnections'));

const DashBoard = () => <div>Home</div>; // Redirect to /login route


const AuthRoutes = () => {
    return (
        <DashboardLayout>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} style={{ marginTops: '10vh' }}>
                    <Suspense
                        fallback={
                            <Grid item xs={12} md={12} lg={12} sx={{ justifyContent: 'center', display: 'flex', marginTop: '50px' }}>
                                <CircularProgress />
                            </Grid>
                        }
                    >
                        <Routes>
                            <Route path="/org/:orgId/dashboard" element={
                            <ProtectedRoute action="read" subject="Dashboard">
                            <OrgDashboard />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/contacts" element={
                            <ProtectedRoute action="read" subject="Contacts">    
                            <Contacts />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/contacts/:contactId" element={
                            <ProtectedRoute action="read" subject="ContactDetail">
                            <ContactDetails />
                            </ProtectedRoute> 
                            } />

                            <Route path="/org/:orgId/contacts/addcontact" element={
                            <ProtectedRoute action="read" subject="AddContact">     
                            <ContactForm />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/contacts/export" element={
                            <ProtectedRoute action="read" subject="ExportContacts">  
                            <ExportContacts />
                            </ProtectedRoute>
                            }
                             />
                            <Route path="/org/:orgId/my-people" element={
                             <ProtectedRoute action="read" subject="MyPeople">
                            <MyPeople />
                             </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/notifications" element={
                            <Notifications />
                            } />
                            <Route path="/org/:orgId/members" element={
                            <ProtectedRoute action="read" subject="TeamMembers">
                             <TeamMembersList />
                            </ProtectedRoute> 
                            } />
                            <Route path="/org/:orgId/my-tasks" element={
                            <MyTasks />
                            } />
                            <Route path="/org/:orgId/my-tasks/:taskId" element={
                            <MyTasksDetailDashboard />
                            } />
                            <Route path="/org/:orgId/admin-tasks" element={
                            <ProtectedRoute action="read" subject="AdminTasks">
                             <Tasks />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/my-tasks/:taskId/survey/:surveyId/responses/:contactId" element={
                            <ViewSurveyResponses />
                            } />
                            <Route path="/org/:orgId/my-tasks/:taskId/:surveyId/:contactId" element={
                            <WalkListDetail />
                            } />
                            <Route path="/org/:orgId/admin-tasks/create" element={
                            <ProtectedRoute action="read" subject="AdminTasks">
                             <Task />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/admin-tasks/:taskId" element={
                            <ProtectedRoute action="read" subject="AdminTasks">
                            <Task />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/surveys" element={
                            <ProtectedRoute action="read" subject="Survey">
                             <Survey />
                            </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/surveys/:surveyId" element={
                            <ProtectedRoute action="read" subject="Survey">
                            <AddOrEditSurvey />
                            </ProtectedRoute>    
                            } />
                            <Route path="/org/:orgId/surveys/create" element={
                            <ProtectedRoute action="read" subject="Survey">
                            <NewSurvey />
                            </ProtectedRoute>
                            } />
                            <Route path="/org" element={
                            <Organisations />
                            } />
                            <Route path="/dashboard" element={
                            <DashBoard />
                            } />
                            <Route path="/org/:orgId/pending-requests" element={
                            <ProtectedRoute action="read" subject="PendingReq">
                             <PendingOrgJoinRequests />
                            </ProtectedRoute>    
                            } />
                            <Route path="/org/:orgId/settings" element=
                            {<Settings />
                            } />
                            <Route path="/org/:orgId/sections" element={
                            <ProtectedRoute action="read" subject="TagsSection">
                                <Sections />
                            </ProtectedRoute>                            
                            } />
                            <Route path="/org/:orgId/segments" element={
                            <ProtectedRoute action="read" subject="Segments">
                                <Segments />
                            </ProtectedRoute>    
                            } />
                            <Route path="/org/:orgId/email-templates" element={
                            <TemplatesList />
                            } />
                            <Route path="/org/:orgId/create-template" element={
                            <EmailTemplateEditor />
                            } />
                            <Route path="/org/:orgId/email-templates/preview-template/:templateID" element={
                            <EmailTemplateEditor />
                            } />
                            <Route path="/org/:orgId/segments/saved-list/:listId" element={
                             <ProtectedRoute action="read" subject="Segments">
                                 <SavedListDetailPage />
                             </ProtectedRoute>   
                            
                            } />
                            <Route path="/org/:orgId/assigned-lists/:listId" element={
                                <ProtectedRoute action="read" subject="Segments">
                                    <SavedListDetailPage />
                                </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/assigned-lists" element={
                            <ProtectedRoute action="read" subject="Segments">
                             <SavedListWrap />
                            </ProtectedRoute>    
                            } />
                            <Route path="/org/:orgId/import-history" element={
                            <ProtectedRoute action="read" subject="ImportHistory">
                                <SheetImportHistory />
                            </ProtectedRoute> 
                            } />
                            <Route path="/org/:orgId/export-history" element={
                                <ProtectedRoute action="read" subject="ExportHistory">
                                 <ExportedContactsHistory />
                                </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/my-connections" element={
                                <ProtectedRoute action="read" subject="MyConnections">
                                     <MyConnections />
                                </ProtectedRoute>
                            } />
                            <Route path="/org/:orgId/email-communications" element={
                                <ProtectedRoute action="read" subject="EmailCampaign">
                                     <EmailCommunications />
                                </ProtectedRoute>
                            } />
                        </Routes>
                    </Suspense>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};
export default AuthRoutes;
import React, { useContext, useState, useEffect } from 'react';
import { AbilityContext } from './AbilityContext';
import UnauthorizedAccessPage from './UnautherisedUI';
import {Grid,CircularProgress} from '@mui/material'

const ProtectedRoute = ({ action, subject, children }) => {
  const ability = useContext(AbilityContext);
  const [isAccessible, setIsAccessible] = useState(null);

  useEffect(() => {
    if(ability){
      const checkPermissions = () => {
        const access = ability.can(action, subject);
        setIsAccessible(access);
      };
      checkPermissions();
    }
  }, [ability, action, subject]);

  if (isAccessible === null ) {
    return <Grid xs="12" sx={{textAlign:"center"}} ><CircularProgress /></Grid>;
  }

  return isAccessible ? children : <UnauthorizedAccessPage />;
};

export default ProtectedRoute;

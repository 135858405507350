import React, { createContext } from 'react';

// Context for providing abilities
export const AbilityContext = createContext();

export const AbilityProvider = ({ ability, children }) => {
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

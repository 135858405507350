import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: { data: null,searchTerm:null},
  reducers: {
    setDashboardGoal: (state, action) => {
      state.data = action.payload;
    },
    clearDashboardGoal: (state) => {
      state.data = null;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    clearSearchTerm: (state) => {
      state.searchTerm = null;
    }
  },
});

export const { setDashboardGoal, clearDashboardGoal,setSearchTerm,clearSearchTerm } = dashboardSlice.actions;
export const dashboardGoal = (state) => state.dashboard.data;
export const contactSearchTerm = (state) => state.dashboard.searchTerm;
export default dashboardSlice.reducer;

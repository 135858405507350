import { createSlice } from '@reduxjs/toolkit';

const taskSlice = createSlice({
  name: 'task',
  initialState: { progress: null,total_contacts: null},
  reducers: {
    setCompletedCount: (state, action) => {
      state.progress = action.payload;
    },
    clearCompletedCount: (state) => {
      state.progress = null;
    },
    setTotalContacts: (state, action) => {
      state.total_contacts = action.payload;
    },
    clearTotalContacts: (state) => {
      state.total_contacts = null;
    }
  },
});

export const { setCompletedCount, clearCompletedCount, setTotalContacts, clearTotalContacts } = taskSlice.actions;
export const completedCount = (state) => state.task.progress;
export const totalContacts = (state) => state.task.total_contacts;
export default taskSlice.reducer;

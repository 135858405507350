import './App.css';
import React, { useState, useEffect } from "react";
import { defineAbilitiesFor } from './ability';
import RouteWrapper from './RouterWrapper';
import { AbilityProvider } from './AbilityContext';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useSelector } from 'react-redux';

function App() {
  const orgInfo =  useSelector((state) => state?.user?.organization);
  const [ability, setAbility] = useState(null);
  const userRole= orgInfo?.attributes?.role?.data?.attributes?.name;
  useEffect(() => {
    if(!userRole){
      return;
    }
    let userInfo = {
      role : userRole
    }
    let ability = defineAbilitiesFor(userInfo);
    setAbility(ability);
  }, [userRole]);
  return (
        <AbilityProvider ability={ability}>
          <RouteWrapper />
        </AbilityProvider>  
    );
}

export default App;

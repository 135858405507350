import {Box} from "@mui/material";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { getAddressFromContact } from "./commonFunctions";

export const formControlStyle = {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
  };

  export const selectStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: '150px',
    backgroundColor: "#FFFFFF"
  };

  export const formatSupportLevel = (supportLevel) => {
      if (!supportLevel) return '-';
      return supportLevel
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  };

  export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
      </div>
    );
  };

  export const formatContactName = (contact) => {
    const { first_name, middle_name, last_name } = contact || {};
    return <span style={{fontWeight:"500"}}>{[first_name, middle_name, last_name].filter(name => name).join(' ')}</span>;
};

export const getFullName = (firstName, middleName, lastName) => {
  return [firstName, middleName, lastName].filter(name => name)?.join(' ');
};

export const getFullAddress = (contact) => {
  return [contact?.street_address, contact?.city, contact?.state, contact?.county, contact?.zip].filter(name => name)?.join(', ');
}

export const formatFileSize = (sizeInBytes) => {
  const kb = 1024;
  const mb = kb * 1024;
  const gb = mb * 1024;

  if (sizeInBytes >= gb) {
    return (sizeInBytes / gb).toFixed(2) + ' GB';
  } else if (sizeInBytes >= mb) {
    return (sizeInBytes / mb).toFixed(2) + ' MB';
  } else if (sizeInBytes >= kb) {
    return (sizeInBytes / kb).toFixed(2) + ' KB';
  } else {
    return sizeInBytes + ' bytes';
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return 'green';
    case 'pending':
      return 'orange';
    case 'failed':
      return 'red';
    case 'cancelled':
      return 'gray';
    default:
      return 'black';
  }
};

export const convertToNumber = (data) => {
  if (data) {
    return parseFloat(data);
  } else {
    return null;
  }
}

export const generateToken = () => {
  return uuidv4();
}

export const formatTimeSpent = (startTime, endTime) => {
  if (!startTime || !endTime) return "-";

  const startMoment = moment(startTime);
  const endMoment = moment(endTime);

  const duration = moment.duration(endMoment.diff(startMoment));

  let timeSpent = '';
  
  if (duration.years() > 0) {
    timeSpent = `${duration.years()} years`;
  } else if (duration.months() > 0) {
    timeSpent = `${duration.months()} months`;
  } else if (duration.days() > 0) {
    timeSpent = `${duration.days()} days`;
  } else if (duration.hours() > 0) {
    timeSpent = `${duration.hours()} hours`;
  } else if (duration.minutes() > 0) {
    timeSpent = `${duration.minutes()} minutes`;
  } else {
    timeSpent = `${duration.seconds()} seconds`;
  }

  return timeSpent || '-';
};

export const mapUserListOptions = (data) => {
  if (!data) {
    return [];
  }
  return data.map(item => {
    if (item?.id && item?.name) {
      return item;
    }
    if (item?.id && item?.contact_id) {
      return {
        id: item?.id,
        name: getFullName(item?.first_name, item?.middle_name, item?.last_name)
      }
    } else if (item?.id && item?.user_first_name) {
      return {
        id: item?.id,
        name: getFullName(item?.user_first_name, '', item?.user_last_name)
      }
    }
    let name = '';
    let contact = item?.attributes?.user?.data?.attributes;
    if (item?.attributes?.default_contact?.data) {
      let defaultContact = item?.attributes?.default_contact?.data?.attributes;
      name = getFullName(defaultContact?.first_name, defaultContact?.middle_name, defaultContact?.last_name);
    } else {
      if (contact?.first_name) {
        name = contact?.first_name;
        if (contact?.last_name) {
          name = name + ' ' + contact?.last_name;
        }
      } else {
        name = contact?.username
      }
    }
    return {
      id: item?.id,
      name,
      username: contact?.username,
      email: contact?.email
    }
  })
}

export const mapContactListOptions = (data) => {
  return data.map(item => {
    return {
      id: item?.id,
      name: getFullName(item?.first_name, item?.middle_name, item?.last_name),
      address: getAddressFromContact(item)
    }
  })
}

import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useParams,useNavigate } from "react-router-dom";

const UnauthorizedAccessPage = () => {
  const {orgId} = useParams();
  const navigate = useNavigate();
  return (
    <Grid
      container
    >
      <Grid item xs={12} md={12} lg={12} style={{textAlign:"center",marginTop:"50px"}}>
        <Typography variant="h3" >
          Unauthorized Access
        </Typography>
        <Typography>
          You do not have permission to access this page.
        </Typography>
        <Button variant="outlined" justifyContent='center' color="primary" onClick={() => navigate(orgId ? `/org/${orgId}/contacts` : "/")} sx={{mt:1}}>
          Go to Home Page
        </Button>
      </Grid>
    </Grid>
  );
};

export default UnauthorizedAccessPage;
